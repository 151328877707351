<template>
  <div>
    <table-component
      ref="tableComponent"
      :model="model"
      :show-form="false"
      url-create-form="/loan-request"
      url-edit-form="/accounts/edit"
      force-paginate
      :permissions="permissions"
    />
  </div>
</template>

<script>
import TableComponent from '@/dc-it/components/TableComponent.vue'
import LoanRequest from '@/dc-it/models/LoanRequest'

export default {
  name: 'LoanRequestsView',
  components: { TableComponent },
  data() {
    return {
      model: LoanRequest,
      // accountType: null,
      permissions: {
        create: true,
        edit: true,
        delete: false,
        filter: true,
      },
    }
  },
}
</script>

<style scoped>

</style>
